import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Lightbox from "lightbox-react";
import "../../node_modules/lightbox-react/style.css";
import Header from "../components/header";
import { Helmet } from "react-helmet";
import Video from "../components/video";
import { slide as Menu } from "react-burger-menu";
var slugify = require("../helpers");

export default class album extends Component {
  constructor(props) {
    // console.log(props);

    super(props);

    this.state = {
      props,
      photoIndex: 0,
      isOpened: false,
      data: props.data,
      taal: props.pageContext,
    };
  }
  getLink(code) {
    const link = "https://www.youtube.com/embed/" + code;
    return <Video video={link} />;
  }
  getMediaLinks() {
    return this.state.data.allMysqlAlbums.edges.map((album, i) => (
      <Link key={i} to={"/media/" + slugify(album.node.title_nl)}>
        {album.node.title_nl}
      </Link>
    ));
  }
  render() {
    const { photoIndex, isOpened } = this.state;

    const lightBoxImages = this.state.data.allMysqlMedia.edges.map(
      (image, i) => {
        let el;
        if (image.node.video) {
          el = this.getLink(image.node.video);
        } else {
          el =
            "https://www.circusronaldo.be/image.php?width=600&image=/uploads/" +
            image.node.foto;
        }
        return el;
      }
    );
    const title = this.state.data.allMysqlMedia.edges[0].node.title_nl;
    const Images = this.state.data.allMysqlMedia.edges.map((image, i) => (
      <div key={image.node.id}>
        <img
          onClick={() => this.setState({ isOpened: true, photoIndex: i })}
          src={
            "https://www.circusronaldo.be/image.php?width=300&height=300&cropratio=1:1&image=/uploads/" +
            image.node.foto
          }
          alt=""
        />
      </div>
    ));
    return (
      <>
        <Helmet
          defaultTitle="Circus Ronaldo"
          style={[
            {
              cssText: `
          .bm-burger-button:after {
            content: "";
            display:none;
          }
        `,
            },
          ]}></Helmet>
        <div className="media">
          <Header back="true" taal="nl" />
          <Menu right>{this.getMediaLinks()}</Menu>
          <h1>{title}</h1>
          {isOpened && (
            <Lightbox
              mainSrc={lightBoxImages[photoIndex]}
              nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
              prevSrc={
                lightBoxImages[
                  (photoIndex + lightBoxImages.length - 1) %
                    lightBoxImages.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpened: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (photoIndex + lightBoxImages.length - 1) %
                    lightBoxImages.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % lightBoxImages.length,
                })
              }
            />
          )}
          <div className="imageHolder">{Images}</div>
        </div>
      </>
    );
  }
}

export const query = graphql`
  query ($id: Int!) {
    allMysqlMedia(
      filter: { media_album_id: { eq: $id } }
      sort: { fields: gewicht }
    ) {
      edges {
        node {
          id
          foto
          media_album_id
          title_nl
          video
        }
      }
    }
    allMysqlAlbums {
      edges {
        node {
          id
          title_nl
        }
      }
    }
  }
`;
